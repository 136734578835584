import React, { useState, useEffect } from 'react';
import { Card, CardMedia, CardContent, Typography, CircularProgress } from '@mui/material';

const App = () => {
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        fetchPhotos();
    }, []);

    const fetchPhotos = async () => {
        try {
            const response = await fetch('https://test-photos-api.azurewebsites.net/api/get-photos?');
            const data = await response.json();
            setPhotos(data);
            setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
            console.error('Error fetching photos:', error);
            setLoading(false); // Set loading to false in case of error
        }
    };

    return (
        <div style={{ backgroundColor: '#E5EAF2', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ maxWidth: 600 }}>
                {loading ? ( // Render CircularProgress while loading
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                        <CircularProgress />
                    </div>
                ) : (
                    photos.map((photo, index) => (
                        <Card key={index} style={{ margin: 15 }}>
                            <CardMedia component="img" height="380" image={photo.url} alt={photo.description} />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {photo.filename}
                                </Typography>
                                <Typography variant="body1" color="textPrimary" component="p">
                                    <b>{photo.captions[0].text}</b>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <b>{photo.translations[0].text}</b>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {photo.tags.join(", ")}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))
                )}
            </div>
        </div>
    );
};

export default App;
